import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { TRANSLATIONS } from '../../../constants';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { AllocationProfile, OperatingHour } from '../../../models';
import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Grid from '../../common/Grid';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';
import Text from '../../common/Text';
import SinaSaveImage from '../../common/SinaSaveImage';
import { ApplicationType } from '../../../types';
import IconButton from '../../common/IconButton';
import { IconType } from '../../common/Icon';
import Modal from '../../common/Modal';
import BasicViewApplicationCardInfo from './ModalInfo/BasicViewApplicationCardInfo';

interface BasicViewApplicationCardProps {
    applicationType: ApplicationType
}

const BasicViewApplicationCard = ({ applicationType }: BasicViewApplicationCardProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const isPumpSystem = applicationType === 'pump';
    const { translate } = useTranslate();
    const { application } = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { regionSettings } = useAppSelector(state => state.regionSettings);
    const { handleShaftPowerChange, getOperatingHourValue, handleOperatingHourChange, handleSpeedChange, getAllocationProfileName, handleAllocationChange } = useHook(x => x.systemsLoad({ applicationType }));
    const applicationMode = useAppSelector(state => isPumpSystem ? state.pumpSystem.applicationMode : state.fanSystem.applicationMode);
    const applicationModeLabel = applicationMode === 'Default' ? translate(TRANSLATIONS.generated['Common.Default']) : translate(TRANSLATIONS.generated['Common.Custom']);

    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const handleInfoModalOpen = useCallback(() => setInfoModalOpen(true), []);
    const handleInfoModalClose = useCallback(() => setInfoModalOpen(false), []);
    const imageStyle: CSSProperties = useMemo(() => ({
        maxWidth: 200
    }), []);

    const numberFormatOptions: NumericFormatProps<InputAttributes> = useMemo(() => ({
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 1,
        allowLeadingZeros: false
    }), []);

    const allocationProfile = useMemo(() => application?.operationProfile.allocationProfile ?? {} as AllocationProfile, [application?.operationProfile.allocationProfile]);

    const allocationProfiles = useMemo(() => application?.allocationProfiles ?? [], [application?.allocationProfiles]);

    const operatingHour = useMemo(() => application?.operationProfile.operatingHour ?? {} as OperatingHour, [application?.operationProfile.operatingHour]);

    const operatingHours = useMemo(() => application?.operatingHours ?? [], [application?.operatingHours]);

    const getLocalizedUnit = useMemo(() => {
        return regionSettings?.countryCode === 'US' ?
            ` ${translate(TRANSLATIONS.generated['System.Units.HP'])}` : ` ${translate(TRANSLATIONS.generated['System.Units.Kilowatt'])}`;
    }, [regionSettings]);

    return (
        <Box dark={dark} style={{ padding: 16 }}>
            <Flex justification='space-between'>
                <Text dark={dark} type='box-title' >{translate(isPumpSystem ? TRANSLATIONS.generated['System.Pump'] : TRANSLATIONS.generated['System.Fan'])}: {applicationModeLabel}</Text>
                <IconButton iconType={IconType.Information} size={16} layer={2} dark={dark} onClick={handleInfoModalOpen} />
            </Flex>
            <Flex columnGap={10} alignment='start' style={{ paddingTop: 24 }}>
                <SinaSaveImage imageName={isPumpSystem ? 'Pump' : 'Fan'} dark={dark} style={imageStyle} />
                <Grid columns={2} columnGap={16} rowGap={16} columns992={1} style={{ paddingLeft: 16, width: '100%' }}>
                    <TextInput
                        dark={dark}
                        value={application?.shaftPower.toString() ?? ''}
                        name='shaftPower'
                        numberFormatOptions={{ ...numberFormatOptions, suffix: getLocalizedUnit }}
                        label={translate(TRANSLATIONS.generated['System.RequiredShaftPowerP'])}
                        onChange={handleShaftPowerChange} />
                    <Select
                        dark={dark}
                        value={operatingHour}
                        values={operatingHours}
                        label={translate(TRANSLATIONS.generated['System.OperatingHoursPerYear'])}
                        onSelect={handleOperatingHourChange}
                        mapToString={getOperatingHourValue}
                    />
                    <TextInput
                        dark={dark}
                        value={application?.speed.toFixed() ?? ''}
                        name={isPumpSystem ? 'pumpSpeed' : 'fanSpeed'}
                        numberFormatOptions={{ ...numberFormatOptions, suffix: ` ${translate(TRANSLATIONS.generated['System.Units.Oneperminutes'])}` }}
                        label={translate(isPumpSystem ? TRANSLATIONS.generated['System.PumpSpeedN'] : TRANSLATIONS.generated['System.FanSpeedN'])}
                        onChange={handleSpeedChange} />
                    <Select
                        dark={dark}
                        value={allocationProfile}
                        values={allocationProfiles}
                        label={translate(TRANSLATIONS.generated['System.Allocation'])}
                        onSelect={handleAllocationChange}
                        mapToString={getAllocationProfileName} />
                </Grid>
            </Flex>
            <Modal open={infoModalOpen} onClose={handleInfoModalClose} dark={dark} allowClickAway movable>
                <BasicViewApplicationCardInfo applicationType={applicationType} />
            </Modal>
        </Box>
    );
};

export default BasicViewApplicationCard;
